
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

@import '~vue-slick-carousel/dist/vue-slick-carousel.css';

// mobile
$carousel-arrow-gap: $spacer !default;
$carousel-arrow-size: $spacer * 2 !default;
$carousel-arrow-bg: rgba($white, 0.33) !default;
$carousel-arrow-border: $border-width solid $gray-300 !default;
$carousel-arrow-icon-size: $font-size-sm !default;
$carousel-arrow-icon-color: $black !default;
$carousel-arrow-transition-in: opacity ease-out 300ms !default;
$carousel-arrow-transition-out: opacity ease-in 300ms !default;

$carousel-arrow-hover-bg: $gray-300 !default;
$carousel-arrow-hover-border: $carousel-arrow-border !default;

$carousel-arrow-outside-gap: $spacer !default;
$carousel-arrow-outside-size: $spacer * 2 !default;
$carousel-arrow-outside-bg: rgba($white, 0.33) !default;
$carousel-arrow-outside-border: $border-width solid $gray-300 !default;
$carousel-arrow-outside-icon-size: $font-size-sm !default;
$carousel-arrow-outside-icon-color: $black !default;

$carousel-dots-padding-y: $spacer * 1 !default;
$carousel-dots-padding-x: 0 !default;
$carousel-dots-max-width: 60% !default;
$carousel-dots-dot-size: $spacer * 0.5 !default;
$carousel-dots-dot-bg: rgba($dark, 0.8) !default;
$carousel-dots-dot-gutter: $spacer * 0.625 !default;

$carousel-dots-dot-active-bg: $dark !default;
$carousel-dots-dot-active-border: 0.13rem solid $carousel-dots-dot-active-bg !default;
$carousel-dots-dot-active-border-offset: nth($carousel-dots-dot-active-border, 1) * -1.9 !default;

// desktop
$carousel-breakpoint: $desktop-breakpoint !default;

$carousel-desktop-arrow-gap: $carousel-arrow-gap !default;
$carousel-desktop-arrow-size: $spacer * 3 !default;
$carousel-desktop-arrow-bg: $carousel-arrow-bg !default;
$carousel-desktop-arrow-border: $carousel-arrow-border !default;
$carousel-desktop-arrow-icon-size: inherit !default;
$carousel-desktop-arrow-icon-color: $black !default;

$carousel-desktop-arrow-hover-bg: $carousel-arrow-hover-bg !default;
$carousel-desktop-arrow-hover-border: $carousel-desktop-arrow-border !default;

$carousel-desktop-arrow-outside-gap: $carousel-arrow-outside-gap !default;
$carousel-desktop-arrow-outside-size: $spacer * 3 !default;
$carousel-desktop-arrow-outside-bg: $carousel-arrow-outside-bg !default;
$carousel-desktop-arrow-outside-border: $carousel-arrow-outside-border !default;
$carousel-desktop-arrow-outside-icon-size: $carousel-desktop-arrow-outside-size * 0.5 !default;
$carousel-desktop-arrow-outside-icon-color: $carousel-arrow-outside-icon-color !default;

$carousel-desktop-dots-inset: $spacer * 2 !default;
$carousel-desktop-dots-padding-y: 0 !default;
$carousel-desktop-dots-padding-x: 0 !default;
$carousel-desktop-dots-dot-size: $spacer * 0.6875 !default;
$carousel-desktop-dots-dot-bg: rgba($white, 0.8) !default;
$carousel-desktop-dots-dot-active-bg: $white !default;
$carousel-desktop-dots-dot-gutter: $spacer * 0.7 !default;

.carousel {
  position: relative;

  .slick-arrow {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    z-index: 3;
    margin: 0;
    padding: 0;
    width: $carousel-arrow-size;
    height: $carousel-arrow-size;
    background-color: $carousel-arrow-bg;
    border: $carousel-arrow-border;
    opacity: 0;
    box-shadow: none;
    border-radius: 50%;
    transform: translate3d(0, -50%, 0);
    transition: $carousel-arrow-transition-out;

    .bi {
      display: block;
      font-size: $carousel-arrow-icon-size;
      color: $carousel-arrow-icon-color;
    }

    &.slick-arrow-prev {
      left: 0;
      margin-left: $carousel-arrow-gap;
    }

    &.slick-arrow-next {
      right: 0;
      margin-right: $carousel-arrow-gap;
    }

    &.slick-disabled {
      display: none;
    }

    .is-touch & {
      opacity: 1;
    }
  }

  .slick-dots {
    @include list-unstyled();
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin: ($carousel-dots-dot-gutter * -0.5) auto;
    padding: $carousel-dots-padding-y $carousel-dots-padding-x;
    max-width: $carousel-dots-max-width;

    li {
      button {
        display: block;
        margin: $carousel-dots-dot-gutter * 0.5;
        padding: 0;
        width: $carousel-dots-dot-size;
        height: $carousel-dots-dot-size;
        background-color: $carousel-dots-dot-bg;
        border: 0 none;
        border-radius: 50%;
      }

      &.slick-active {
        button {
          position: relative;
          background-color: $carousel-dots-dot-active-bg;

          &:before {
            display: block;
            content: '';
            position: absolute;
            inset: $carousel-dots-dot-active-border-offset;
            border: $carousel-dots-dot-active-border;
            border-radius: inherit;
          }
        }
      }
    }
  }

  .slick-list {
    z-index: 1;
    transition: max-height 150ms ease-out;

    .slick-track {
      display: flex;
      align-content: stretch;

      .slick-slide {
        position: relative;
        float: none;
        height: auto;
        overflow: hidden;

        > div {
          height: 100%;
        }

        [style*='inline-block'] {
          display: block !important;
        }

        img {
          display: block;
          width: 100%;
          max-width: none;
          height: auto;
        }
      }
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
      transition: $carousel-arrow-transition-in;
    }
  }

  &.arrows-outside {
    .slick-arrow {
      top: calc(100% + #{$carousel-arrow-outside-gap});
      margin: 0;
      width: $carousel-arrow-outside-size;
      height: $carousel-arrow-outside-size;
      opacity: 1;
      transform: none;

      &.slick-arrow-prev {
        left: auto;
        right: 50%;
      }

      &.slick-arrow-next {
        left: 50%;
        right: auto;
      }
    }

    .slick-dots {
      margin-bottom: calc(#{$carousel-arrow-outside-gap * 2} + #{$carousel-arrow-outside-size});
    }

    &:not(.has-dots) {
      .slick-list {
        margin-bottom: 0;
      }
    }
  }

  &.adaptive-height {
    .slick-list {
      .slick-track {
        align-content: flex-start;

        .slick-slide {
          > div {
            height: auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-up($carousel-breakpoint) {
    .slick-arrow {
      width: $carousel-desktop-arrow-size;
      height: $carousel-desktop-arrow-size;
      background-color: $carousel-desktop-arrow-bg;
      border: $carousel-desktop-arrow-border;
      display: flex;

      .bi {
        font-size: $carousel-desktop-arrow-icon-size;
        color: $carousel-desktop-arrow-icon-color;
      }

      &.slick-arrow-prev {
        margin-left: $carousel-desktop-arrow-gap;
      }

      &.slick-arrow-next {
        margin-right: $carousel-desktop-arrow-gap;
      }

      &:hover {
        background-color: $carousel-desktop-arrow-hover-bg;
        border: $carousel-desktop-arrow-hover-border;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: $carousel-desktop-dots-inset;
      left: 0;
      right: 0;
      margin: ($carousel-desktop-dots-dot-gutter * -0.5) auto;
      padding: $carousel-desktop-dots-padding-y $carousel-desktop-dots-padding-x;

      li {
        button {
          margin: $carousel-desktop-dots-dot-gutter * 0.5;
          width: $carousel-desktop-dots-dot-size;
          height: $carousel-desktop-dots-dot-size;
          background-color: $carousel-desktop-dots-dot-bg;
        }

        &.slick-active {
          button {
            background-color: $carousel-desktop-dots-dot-active-bg;

            &:before {
              border-color: $carousel-desktop-dots-dot-active-bg;
            }
          }
        }
      }
    }

    &.arrows-outside {

      .slick-arrow {
        top: 50%;
        width: $carousel-desktop-arrow-outside-size;
        height: $carousel-desktop-arrow-outside-size;
        transform: translate3d(0, -50%, 0);

        &.slick-arrow-prev {
          right: calc(100% + #{$carousel-desktop-arrow-outside-gap});
        }

        &.slick-arrow-next {
          left: calc(100% + #{$carousel-desktop-arrow-outside-gap});
        }
      }

      .slick-dots {
        margin-bottom: 0;
      }
    }
  }
}
