
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productteaser-line-height: 1.2 !default;
$productteaser-transiton: $transition-base !default;

$productteaser-img-fallback-size: 80% !default;
$productteaser-img-fallback-color: $imgfallback !default;

$productteaser-price-font-size: $font-size-lg !default;

$productteaser-hover-boxshadow: map-get($shadows, 'productteaser') !default;

.product-teaser {
  height: 100%;
  line-height: $productteaser-line-height;
  transition: $productteaser-transiton;
  position: relative;

  .product-teaser-contentplaceholder {
    height: 100%;
  }

  .card {
    height: 100%;

    .card-img,
    .card-img-top,
    .card-img-bottom,
    .card-img-left,
    .card-img-right {
      margin: 0;

      img {
        display: block;
        width: 100%;
        max-width: none;
        object-fit: scale-down;
        height: 100%;
      }

      .is-fallback {
        .bi {
          display: block;
          position: absolute;
          inset: 0;
          margin: auto;
          width: $productteaser-img-fallback-size;
          height: $productteaser-img-fallback-size;
          color: $productteaser-img-fallback-color;
        }
      }
    }

    .card-header,
    .card-footer {
      background-color: transparent;
      border: 0 none;
    }

    .card-header {
      padding-bottom: 0;
    }

    .card-body {
      position: relative;
      @include clearfix();
    }

    .card-footer {
      padding-top: 0;

      // Fix because of the stretched link
      z-index: 2;
    }

    .product-deliverystate {
      float: right;
      margin: 0;

      .bi {
        margin: 0;

        // Fix because of the stretched link
        z-index: 2;
      }
    }

    .eyecatcher-stack {
      position: absolute;
      top: 0;
      right: 0;
      margin: $card-spacer-y $card-spacer-x 0 0;
      z-index: 2;
    }

    .product-description {}

    .product-price {

      .price-active,
      .price-original {
        font-size: $productteaser-price-font-size;
      }
    }

    .product-colors {}

    .product-wishlistbutton {}
  }

  &:hover {
    box-shadow: $productteaser-hover-boxshadow;

    .carousel & {
      box-shadow: set-nth($productteaser-hover-boxshadow, 3, $grid-gutter-width * 0.5);
    }
  }

  &.has-link {
    cursor: pointer;
  }
}
