
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

// desktop
$testimonialcarousel-desktop: $desktop-breakpoint !default;

$testimonialcarousel-slide-border: map-get($borders, 'base') !default;

.testimonial-carousel {
  @include component('y');

  @include media-breakpoint-up($testimonialcarousel-desktop) {
    .carousel {
      .slick-list {
        .slick-track {
          .slick-slide {
            border-left: $testimonialcarousel-slide-border;
          }
        }
      }
    }
  }
}
