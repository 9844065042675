
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$gridcarousel-gutter: $grid-gutter-width !default;

// tablet
$gridcarousel-tablet: $tablet-breakpoint !default;

$gridcarousel-tablet-gutter: $grid-gutter-width !default;

.grid-carousel {
  @include fullwidth-mobile();

  .carousel {
    .slick-list {
      margin: ($gridcarousel-gutter * -1) ($gridcarousel-gutter * -0.5);
      padding-left: $grid-gutter-width * 0.5;

      .slick-track {
        .slick-slide {
          padding: $gridcarousel-gutter ($gridcarousel-gutter * 0.5);
          padding-right: $gridcarousel-gutter * 3.5;

          &.slick-current {
            ~ .slick-slide {
              margin-left: $gridcarousel-gutter * -3;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up($gridcarousel-tablet) {
    .carousel {
      .slick-list {
        margin: ($gridcarousel-tablet-gutter * -1) ($gridcarousel-tablet-gutter * -0.5);
        padding-left: 0;

        .slick-track {
          .slick-slide {
            padding: $gridcarousel-tablet-gutter ($gridcarousel-tablet-gutter * 0.5);

            &.slick-current {
              ~ .slick-slide {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
